import { GET_POSTS_PAGINATED } from "@/src/gql/queries/posts";
import { ArticleCardType } from "@/src/types";
import { DocumentNode, useLazyQuery, useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useOnScreen from "@/src/utils/hooks";
import { Spinner } from "@material-tailwind/react";
import FadeInArticleList from "../ArticleList/FadeInArticleList";
import Tabs from "../blocks/TopPosts.tsx/Tabs";
import { SeenArticle } from "@/pages";

export interface LoadMoreContainerProps {
  initialArticles: ArticleCardType[];
  ignoreIds: string[];
  loadMoreQuery?: DocumentNode;
  loadMoreVariables: any;
  initialCursor?: string;
  convertPayload?: (object: any) => {
    posts: ArticleCardType[];
    thelastCursor: string;
  };
}

const LoadMoreContainer = ({
  initialArticles,
  ignoreIds = [],
  loadMoreQuery = GET_POSTS_PAGINATED,
  loadMoreVariables = {},
  initialCursor = "",
  convertPayload,
}: LoadMoreContainerProps) => {
  const node = useRef(null);
  const [articlesList, setArticlesList] =
    useState<ArticleCardType[]>(initialArticles);
  const [lastCursor, setLastCursor] = useState(initialCursor);
  const [buttonClicked, setButtonClicked] = useState(true);
  const [done, setDone] = useState(false);

  const isBottomVisible = useOnScreen(node);
  const [fetchPosts, { loading, error }] = useLazyQuery(loadMoreQuery, {
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      let articles = [];
      let lastCursor = "";
      if (!convertPayload) {
        articles = data.posts.edges.map(function (post: {
          node: ArticleCardType;
        }) {
          return post.node;
        });
        lastCursor = data.posts.edges[data.posts.edges.length - 1]?.cursor
          ? data.posts.edges[data.posts.edges.length - 1]?.cursor
          : "";
      } else {
        const { posts, thelastCursor } = convertPayload(data);
        articles = posts;
        lastCursor = thelastCursor;
      }

      if (articles.length === 0) {
        setDone(true);
      }

      setArticlesList([...articlesList, ...articles]);

      setLastCursor(lastCursor);
    },
    onError: (error) => {
      console.log("USELAZY ERROR: ", error);
    },
  });

  const fireLoadMore = () => {
    fetchPosts({
      variables: {
        after: lastCursor ? lastCursor : "",
        amount: 10,
        notIn: ignoreIds,
        ...loadMoreVariables,
      },
    });
  };

  useEffect(() => {
    if (isBottomVisible && !done) {
      if (buttonClicked) {
        setTimeout(() => {
          fireLoadMore();
        }, 500);
      }
    }
    //eslint-disable-next-line
  }, [buttonClicked, isBottomVisible, done]);

  useEffect(() => {
    setArticlesList(initialArticles);
  }, [initialArticles]);

  return (
    <div className={"load-more-container flex flex-col items-center gap-2"}>
      <div style={{ width: "100%", display: "flex", alignItems: "start" }}>
        <Tabs
          tabItems={[]}
          setTabItem={() => {}}
          lhsHeader="Recent Articles"
          showTabs={false}
        />
      </div>
      <div className="px-2 md:px-0">
        <FadeInArticleList articles={articlesList || []} />
      </div>
      {!done ? (
        <div
          className={`transition-all bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex mx-auto w-[200px] text-center justify-center ${
            loading ? "opacity-50" : "opacity-80"
          }`}
          onClick={() => {
            setButtonClicked(!buttonClicked);
            fireLoadMore();
          }}
        >
          {loading && (
            <>
              <span>Loading</span>&nbsp;
              <Spinner />{" "}
            </>
          )}
          {!loading && !buttonClicked && "Click To Load More..."}
          {!loading && buttonClicked && "Click To Stop"}
        </div>
      ) : (
        <div className={"h-[40px]"}></div>
      )}
      <div
        className={`loading-bottom ${isBottomVisible ? "vis" : "not-vis"}`}
        ref={node}
      >
        &nbsp;
      </div>
    </div>
  );
};

export default LoadMoreContainer;
