const PostFragment = `
 fragment PostFragment on Post {
  id
  title
  excerpt
  slug
  featuredImage {
    node {
      ...ImageFragment
    }
  }
 }
`;
export default PostFragment;

export const CardFragment = `
  id
  featuredImage {
    node {
      mediaDetails {
        sizes(include: [FP_SMALL, FP_LARGE, LARGE]) {
          sourceUrl
        }
      }
    }
  }
  slug
  title(format: RENDERED)
  uri
  articleOptions {
    byline
    frontPageTitle
    highlight
    highlightText
  }
  categories {
    edges {
      node {
        id
        name
        uri
      }
    }
  }
  date
`;

export const ContributorFragment = `
contributors {
  nodes {
    id
    uri
    contributor {
      bio
      facebookLink
      instagramLink
      twitterLink
      image {
        mediaItemUrl
        srcSet
      }
    }
    contributorId
    name
  }
}`;

export const ContributorFragmentWithPosts = `
contributors {
  nodes {
    id
    uri
    contributormeta {
      bio
      facebookLink
      instagramLink
      twitterLink
      oneLineBio
      image {
        node {
          mediaItemUrl
          srcSet
        }
      }
      
    }
    posts(first: 5) {
      edges {
        node {
          ${CardFragment}
        }
      }
    }
    contributorId
    name
  }
}`;
