import React from "react";

export interface LozengeProps {
  text: string;
  color?: "light" | "dark";
}

const Lozenge = ({ text, color = "dark" }: LozengeProps) => {
  return (
    <span
      className={`rounded px-2 py-0.5 text-xs text-white whitespace-nowrap ${
        color === "light" ? `bg-scpb-light-blue` : "bg-scpb-dark-blue"
      }`}
    >
      {text}
    </span>
  );
};

export default Lozenge;
