import { ArticleCardType } from "@/src/types";
import React from "react";
import FlagArticle from "../../cards/FlagArticle";

export interface FlagArticleItem {
  position: 0 | 1 | 2;
  article: {
    heroArticlePostItem: {
      nodes: ArticleCardType[];
    };
  };
}

export interface FlagContainerProps {
  flags: FlagArticleItem[];
  hideSubTextForMobile?: boolean;
}

const GridPositions = {
  0: "md:row-span-2 md:col-span-3",
  1: "row-span-1 col-span-2",
  2: "row-span-1 col-span-2",
};

const FlagContainer = ({
  flags,
  hideSubTextForMobile = false,
}: FlagContainerProps) => {
  return (
    <div
      className={
        "flag-container grid grid-rows-3 grid-cols-1 md:grid-rows-2 md:grid-cols-5 grid-flow-col md:gap-0 h-full sm:min-h-80vh md:min-h-75vh lg:max-h-[75vh]"
      }
    >
      {flags?.map(function (flag) {
        return (
          <div
            className={`${GridPositions[flag.position]} `}
            key={flag.article.heroArticlePostItem.nodes[0]?.uri}
          >
            <FlagArticle
              article={flag.article.heroArticlePostItem.nodes[0]}
              position={flag.position}
              hideSubTextForMobile={hideSubTextForMobile}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FlagContainer;
