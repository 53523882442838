import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Tooltip } from "@material-tailwind/react";

const PremiumIcon = () => {
  return (
    <Tooltip content="Premium Article">
      <span>👑&nbsp;</span>
    </Tooltip>
  );
};

export default PremiumIcon;
