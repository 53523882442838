//@ts-nocheck
import { ArticleCardType } from "@/src/types";
import React from "react";
import ArticleCard from "../cards/ArticleCard2";
import { Transition } from "react-transition-group";

export interface ArticleListProps {
  articles: ArticleCardType[];
}

const defaultStyle = {
  transition: `opacity 300ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const FadeInArticleList = ({ articles }: ArticleListProps) => {
  return (
    <div
      className={
        "grid grid-cols-1 gap-2 md:gap-4 md:grid-cols-2 lg:grid-cols-3"
      }
    >
      {articles.map(function (article, index) {
        return (
          <Transition
            in={true}
            mountOnEnter={true}
            appear={true}
            timeout={300}
            key={`${index}-${article.id}`}
          >
            {(state) => (
              <div
                style={{ ...defaultStyle, ...transitionStyles[state] }}
                key={article.id}
              >
                <ArticleCard post={article} key={`${article.id}`} />
              </div>
            )}
          </Transition>
        );
      })}
    </div>
  );
};

export default FadeInArticleList;
