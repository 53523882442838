import React, { useState } from "react";
import Image from "next/image";
import { SCPB_LOGOS } from "@/src/constants";

export interface ImageWithFullbackProps {
  src: string;
  fallbackSrc?: string;
  [key: string]: any;
}

const fallbackStyles = {
  backgroundSize: "contain !important",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  //   backgroundImage: `url(${SCPB_LOGOS["Small Blue Black"]})`,
};

const ImageWithFallback = ({
  src,
  fallbackSrc = SCPB_LOGOS["Small Blue Black"],

  ...rest
}: ImageWithFullbackProps) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [isFallback, setIsFallback] = useState(false);
  const [style, setStyle] = useState(rest.style);
  function onError() {
    setImgSrc(fallbackSrc);
    setIsFallback(true);
    setStyle({ ...rest.styles, ...fallbackStyles });
  }

  return (
    <Image
      {...rest}
      src={imgSrc}
      onError={onError}
      alt={fallbackSrc}
      style={style}
    />
  );
};

export default ImageWithFallback;
