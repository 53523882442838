import { Typography } from "@material-tailwind/react";
import React from "react";

interface TabItem {
  label: string;
  value: string;
  selected: boolean;
}

export interface TabsProps {
  tabItems: TabItem[];
  setTabItem: (value: string) => void;
  lhsHeader?: string;
  showTabs?: boolean;
}

const Tabs = ({
  tabItems = [],
  lhsHeader = "Latest",
  showTabs = true,
  setTabItem,
}: TabsProps) => {
  const handleClick = (e: object) => {
    //@ts-expect-error Stupid click
    e.currentTarget.scrollIntoViewIfNeeded(false, { behaviour: "smooth" });
  };

  return (
    <div className={"flex flex-col md:py-2 md:px-0"}>
      <div
        className={
          "transition-all cursor-pointer py-2 border-b-2 border-transparent text-slate-600 px-2 md:px-0"
        }
      >
        <Typography
          variant="paragraph"
          className={"font-bold text-2xl whitespace-nowrap text-slate-900"}
        >
          {lhsHeader}
          <span className={"highlight text-lg font-bold "}>&nbsp;/</span>
        </Typography>
      </div>
      <div className="flex flex-row overflow-y-scroll pl-2 md:pl-0">
        {showTabs &&
          tabItems.map(function (tabItem) {
            return (
              <div
                className={`transition-all cursor-pointer px-4 py-2 ${
                  tabItem.selected
                    ? "border-b-2 border-scpb-light-blue text-slate-900"
                    : "border-b-2 border-transparent text-slate-600"
                }`}
                onClick={(e) => {
                  handleClick(e);
                  setTabItem(tabItem.value);
                }}
                key={tabItem.value}
              >
                <Typography
                  variant="paragraph"
                  className={`transition-all text-lg whitespace-nowrap ${
                    tabItem.selected ? "font-bold" : "font-normal"
                  }`}
                >
                  {tabItem.label[0].toUpperCase()}
                  {tabItem.label.slice(1)}
                </Typography>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Tabs;
