import { ArticleCardType } from "@/src/types";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import Lozenge from "../accoutrements/lozenge";
import { Typography } from "@material-tailwind/react";
import Image from "next/image";
import PremiumIcon from "../accoutrements/PremiumIcon";
import { WP_URL } from "@/src/constants";

export interface FlagArticleProps {
  article: ArticleCardType;
  position: 0 | 1 | 2;
  hideSubTextForMobile?: boolean;
}

export const FlagArticle = ({
  article,
  position,
  hideSubTextForMobile = false,
}: FlagArticleProps) => {
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    const theIndex = article.categories?.edges?.findIndex(function (category) {
      return category.node.name === "Subscriber Only";
    });
    setIsPremium(theIndex !== -1);
    //eslint-disable-next-line
  }, [article?.categories?.edges?.length]);

  const theCategory = article.categories?.edges[0];
  const highlightText =
    article.articleOptions.highlight === "category"
      ? theCategory?.node?.name
      : article.articleOptions.highlightText;

  let featuredImage: string = "";
  if (article?.featuredImage?.node?.mediaDetails?.sizes) {
    featuredImage =
      article?.featuredImage?.node?.mediaDetails?.sizes[0]?.sourceUrl;
  } else if (article?.featuredImage?.node?.uri) {
    featuredImage = `${WP_URL}${article?.featuredImage?.node?.uri}`;
  }
  return (
    <Link href={`/blog${article.uri}`}>
      <div
        className={`flag-article text-white pt-20 md:pt-0 p-4 pb-4 md:pb-2 md:p-10 lg:p-6 px-4 md:px-4 flex flag-article-${position} shadow-lg ${
          position === 0 && `md:minh-[430px]`
        }`}
      >
        <Image
          alt={`${article.articleOptions.frontPageTitle} - cover image`}
          src={featuredImage}
          fill
          style={{
            objectFit: "cover",
          }}
          className="image flag-article rounded"
          priority
        />
        <div
          className={`text-container flex flex-col items-start mt-auto text-white w-full ${
            position === 0 ? "md:w-100 lg:w-100 xl:max-w-90p" : "md:w-100"
          } leading-5 gap-1 md:px-0 pt-12 md:pt-0`}
        >
          <div className="flex w-full">
            {isPremium && <PremiumIcon />}

            {highlightText && highlightText !== "" && (
              <Lozenge text={highlightText} color="dark" />
            )}
          </div>
          <p
            className={
              "text-xl md:text-2xl leading-7 font-bold md:mb-1 text-white font-heading"
            }
          >
            {article.articleOptions.frontPageTitle}
          </p>
          <Typography
            variant={"paragraph"}
            className={hideSubTextForMobile ? "hidden md:block" : ""}
          >
            {article.articleOptions.byline}{" "}
            <span className="font-bold">See More</span>
          </Typography>
        </div>
        <div className={"article-bg-gradient"}>
          <div className="top-gradient"></div>
          <div className="bottom-gradient"></div>
        </div>
      </div>
    </Link>
  );
};

export default FlagArticle;
